.row {
    display: block;
    justify-content: center;
    align-items: center;
    padding: 15px;
}


.img {
    max-width: 75%;
}
