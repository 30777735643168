form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;

}


.MuiTextField-root {
    width: 300px;
    margin: 8px;
}

.MuiButtonBase-root {
    margin: 16px;
}

