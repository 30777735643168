* {
  box-sizing: border-box;
}

body {
  margin: 0;
 /* background: linear-gradient(90deg, rgba(0,197,255,0.8603641285615808) 0%, rgba(127,87,209,0.7259103470489758) 40%, rgba(152,66,201,0.7399159492898721) 60%);*/
 background: url("../public/v5.png");
 background-size: contain;
}

.container {
  text-align: center;
  margin: 0;

}

.nav {
  background: linear-gradient(90deg, rgba(0,17,112,1) 0%, rgba(96,47,200,1) 50%, rgba(205,22,167,1) 100%);
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
  height: 66px;
}

.site-title {
  font-size: 4vh;
  margin-right:auto;
  font-family: 'Libre Baskerville', serif;
}

.nav ul {
  padding: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0 20px 0 0;
  font-family: 'Libre Baskerville', serif;
  font-size: 8px;
  font-size: 2vh;

}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  color:black ;
}

.nav li:hover {
 text-shadow: 1px 1px 3px #444444;
}

.acm-logo {
  margin-top: 4px;
  margin-bottom: 4px;
}

.syn-container {
  height: 700px;
  width: 100%;
  overflow: hidden;
  vertical-align: bottom;
}